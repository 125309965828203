<template>
  <div style="padding: 0 8px;">
    <el-form class="fresh" label-width="120px" label-suffix=":" label-position="left">
      <el-row>
        <el-col :span="24">
          <label>车辆信息</label>
          <el-form-item label="车牌号码" required><el-color-picker v-model="item.color1"></el-color-picker><el-input v-model="item.VehicleNum"></el-input></el-form-item>
          <el-form-item label="车架号码">LHGTF3852D8026254</el-form-item>
          <el-form-item label="发动机号"><el-input v-model="item.VehicleNum"></el-input></el-form-item>
          <el-form-item label="用车区域" required>
            <el-select v-model="item.Province" style="width:100%;" :placeholder="$t('deviceDetail.pleaseSelectProvince')">
              <el-option v-for="k in cityOptions" :label="k.name" :value="k.value" :key="k.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车辆类型" required>
            <select-brand
              title=""
              size="28px"
              style="display:inline-block;"
              v-model="item.VehicleTypeID"
              :options="other.carTypeList"
              :props="other.carTypeProps"
              folder="car-type"
              extension="svg"
              transform-name
              placement="bottom-start"></select-brand>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-for="(k, index) in item.Detail" :key="index">
        <el-col :span="24">
          <label>设备{{index+1}}</label>
          <el-form-item label="类型">
            <el-radio :label="0" v-model="k.TrackerType">有线设备</el-radio>
            <el-radio :label="1" v-model="k.TrackerType">无线设备</el-radio>
          </el-form-item>
          <el-form-item label="设备ID" required>
            <el-input v-model="k.SIM2"></el-input>
          </el-form-item>
          <el-form-item label="SIM卡号" required>
            <el-input v-model="k.SIM"></el-input>
          </el-form-item>
          <el-form-item label="安装位置" required>
            <el-select v-model="k.InstallPosition">
              <el-option label="1.排挡右侧" value="1.排挡右侧"></el-option>
              <el-option label="7.OBD" value="7.OBD"></el-option>
            </el-select>
            <p class="hightip imt-1">
              位置示例: <img :src="other.imgUrl" style="max-height: 100px;vertical-align:middle;border:1px dashed #CCC;"/>
            </p>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <label>安装照片</label>
          <el-form-item label="全车身照">
            <div class="btn-box"><i class="el-icon-plus"></i></div>
            <div>
              <p class="hightip">
                <ul style="list-style-position:inside;">
                  <li>照片应包含整车外观</li>
                  <li>从车辆前脸斜45°采集</li>
                  <li>保持车辆在照片中央，并排除与车体无关的物体</li>
                </ul>
              </p>
              <p class="hightip">照片示例：<img :src="`${DOWNLOAD_URL}/static/image/install-example1.png`" style="max-height: 100px;vertical-align:middle;border:1px dashed #CCC;"/></p>
            </div>
          </el-form-item>
          <el-form-item label="车架号+设备号">
            <div class="btn-box"><i class="el-icon-plus"></i></div>
            <div>
              <p class="hightip">
                <ul style="list-style-position:inside;">
                  <li>照片应包含车辆前挡风玻璃下方车架号及所安装的所有设备的SN</li>
                  <li>应确保图片中的相关资料清晰明确</li>
                </ul>
              </p>
              <p class="hightip">照片示例：<img :src="`${DOWNLOAD_URL}/static/image/install-example2.png`" style="max-height: 100px;vertical-align:middle;border:1px dashed #CCC;"/></p>
            </div>
          </el-form-item>
          <el-form-item label="手指位置">
            <div class="btn-box"><i class="el-icon-plus"></i></div>
            <div>
              <p class="hightip">
                <ul style="list-style-position:inside;">
                  <li>照片应拍摄设备的藏匿位置，如位置隐蔽可用手辅助示意</li>
                  <li>优先拍摄无源设备的位置，若无需安装无源，可以用有源设备的安装位置替代</li>
                </ul>
              </p>
              <p class="hightip">照片示例：<img :src="`${DOWNLOAD_URL}/static/image/install-example3.png`" style="max-height: 100px;vertical-align:middle;border:1px dashed #CCC;"/></p>
            </div>
          </el-form-item>
          <el-form-item label="安装店面门头">
            <div class="btn-box"><i class="el-icon-plus"></i></div>
            <div>
              <p class="hightip">
                <ul style="list-style-position:inside;">
                  <li>照片需包含门头</li>
                </ul>
              </p>
              <p class="hightip">照片示例：<img :src="`${DOWNLOAD_URL}/static/image/install-example4.png`" style="max-height: 100px;vertical-align:middle;border:1px dashed #CCC;"/></p>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <label>其他</label>
          <el-form-item label="工单备注信息">
            <el-input type="textarea" :rows="2" v-model="item.Remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { QueryDistrict} from '@/api/device'
import { DOWNLOAD_URL } from '@/api/config'
import { QueryCarType, QueryBrandGroup } from '@/api/common'
import SelectBrand from '@/views/common/select-brand'
export default {
  data() {
    return {
      item: {
        color1: '#409EFF',
        Province: '广东省',
        Detail: [
          {
            SIM2: '',
            SIM: '',
            TrackerType: 0,
            InstallPosition: '1.排挡右侧'
          },
          {
            SIM2: '',
            SIM: '',
            TrackerType: 1,
            InstallPosition: '7.OBD'
          }
        ]
      },
      other: {
        brandList: [],
        brandProps: {
          label: 'VehicleBrandName',
          value: 'VehicleBrandID'
        },
        cityOptions: [],
        carTypeList: [],
        carTypeProps: {
          label: 'ItemName',
          value: 'ItemID'
        },
        imgUrl: `${DOWNLOAD_URL}/static/image/installposition.jpg`
      },
      DOWNLOAD_URL
    }
  },
  components: {
    SelectBrand
  },
  methods: {
    fetchData() {
      QueryBrandGroup(1, 100, '', '', []).then(ret => {
        this.other.brandList = ret.data.dict
      })
      QueryCarType(1, 100, '', '', []).then(ret => {
        this.other.carTypeList = ret.data.list.slice()
      })
    },
    // getCityOptions() {
    //   const data = require('@/common/city.json')
    //   let citys = data.districts[0].districts.map(i => {
    //     return {
    //       name: i.name,
    //       value: i.name
    //     }
    //   })
    //   citys = citys.sort( (a, b) => {
    //     return a.name.localeCompare(b.name)
    //   })
    //   //console.log(citys.map(k => k.name).join('/t'))
    //   citys.splice(0, 0, {
    //     name: '--',
    //     value: ''
    //   })
    //   this.cityOptions = citys
    // },
     getCityOptions() {
      return new Promise((resolve, reject) => {
        QueryDistrict().then(ret => {
          let citys = ret.data.list.map(i => {
            return {
              name: i.DName,
              value: i.DName
            }
          })
          citys = citys.sort( (a, b) => {
            return a.name.localeCompare(b.name)
          })
          //console.log(citys.map(k => k.name).join('/t'))
          citys.splice(0, 0, {
            name: '--',
            value: null
          })
          this.cityOptions = citys
          resolve(ret.data.list)
        }).catch(err => reject(err))
      })
    }
  },
  created() {
    this.fetchData()
    this.getCityOptions()
  }
}
</script>

<style lang="scss" scoped>

</style>
